import React from "react"
import { Link } from "gatsby"
import classes from "./Sidebar.module.scss"

import cancel from "../../../images/sidebar/cancel.svg"
import contact from "../../../images/sidebar/contact.svg"
import group from "../../../images/sidebar/group.svg"
import health from "../../../images/sidebar/health.svg"
import home from "../../../images/sidebar/home.svg"
import price from "../../../images/sidebar/price-tag.svg"
import treatment from "../../../images/sidebar/treatment.svg"

const Sidebar = ({ opened, close}) => {
  const menuClasses = [classes.sideMenu]
  opened ? menuClasses.push(classes.opened) : menuClasses.push(classes.closed)

  return (
    <div className={menuClasses.join(" ")}>
      <div className={classes.toppom_block}>
        <img
          className={classes.cancelIcon}
          src={cancel}
          alt={cancel}
          onClick={() => close(false)}
        />
        <img className={classes.heartIcon} src={health} alt={health} />
      </div>

      <div className={classes.links}>
        <Link to="/" activeClassName={classes.active}>
          <img src={home} alt="home" />
          <p>Dom</p>
        </Link>
        <Link to="/cennik/" activeClassName={classes.active}>
          <img src={price} alt="price-tag" />
          <p>Cennik</p>
        </Link>
        <Link to="/zabiegi/" activeClassName={classes.active}>
          <img src={treatment} alt="treatment" />
          <p>Zabiegi</p>
        </Link>
        <Link to="/kontakt/" activeClassName={classes.active}>
          <img src={contact} alt="contact" />
          <p>Kontakt</p>
        </Link>
        <Link to="/o-nas/" activeClassName={classes.active}>
          <img src={group} alt="group" />
          <p>O nas</p>
        </Link>
      </div>
    </div>
  )
}

export default Sidebar
