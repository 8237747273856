import React, { useState } from "react"
import { Link } from "gatsby"

import classes from "./Navbar.module.scss"
import menu from "../../images/menu.svg"
import Sidebar from "./Sidebar/Sidebar"
import Backdrop from "./Backdrop/Backdrop"

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false)

  return (
    <>
      <Sidebar opened={sidebar} close={setSidebar} />
      <Backdrop show={sidebar} close={setSidebar} params={false} />
      <div className={classes.navbar}>
        <Link to="/" className={classes.logo}>
          REH-MED
        </Link>
        <img
          className={classes.open_menu_icon}
          src={menu}
          alt={menu}
          onClick={() => {
            setSidebar(true)
          }}
        />
        <div className={classes.items}>
          <Link to="/o-nas/" activeClassName={classes.active}>
            O nas
          </Link>
          <Link to="/cennik/" activeClassName={classes.active}>
            Cennik
          </Link>
          <Link to="/zabiegi/" activeClassName={classes.active}>
            Zabiegi
          </Link>
          <Link to="/kontakt/" activeClassName={classes.active}>
            Kontakt
          </Link>
        </div>
      </div>
    </>
  )
}

export default Navbar
