import React from "react"
import classes from "./footer.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined"
import PhoneIcon from "@material-ui/icons/Phone"
import RoomIcon from "@material-ui/icons/Room"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      strapiKontakt {
        email
        ulica
        numer_budynku
        kod_pocztowy
        miasto
        rejestracja_telefon
        telefon_sala_gim
      }
    }
  `)

  return (
    <footer className={classes.footerContainer}>
      <div className={classes.informationContainer}>
        <div className={classes.flexElement}>
          <p className={classes.icon}>
            <EmailOutlinedIcon />
          </p>
          <a href={"mailto:" + data.strapiKontakt.email} className={classes.text}>{data.strapiKontakt.email}</a>
        </div>
        <div className={classes.flexElement}>
          <p className={classes.icon}>
            <PhoneIcon />
          </p>
          <a href={"tel:" + data.strapiKontakt.rejestracja_telefon} className={classes.text}>
            Umów wizytę tel: {data.strapiKontakt.rejestracja_telefon}
          </a>
        </div>
        <div className={classes.flexElement}>
          <p className={classes.icon}>
            <RoomIcon />
          </p>
          <a href={`https://www.google.pl/maps/place/${data.strapiKontakt.ulica}+${data.strapiKontakt.numer_budynku},${data.strapiKontakt.miasto}/`} className={classes.text}>
            {data.strapiKontakt.ulica} {data.strapiKontakt.numer_budynku},{" "}
            {data.strapiKontakt.miasto}
          </a>
        </div>
      </div>
      <div className={[classes.copyrightContainer, classes.informationContainer].join(' ')}>
        <p className={[classes.flexElement, classes.copyrightText].join(' ')}>
          Copyright 2020 ©Harp0n. Wszelkie prawa zastrzeżone. Stworzene przy pomocy <span className={classes.harponText}>Strapi </span> oraz <span className={classes.harponText}>Gatsby</span>. <br></br>
          Źródło części zdjęć <span className={classes.harponText}>unsplash</span>. Użyto biblioteki <span className={classes.harponText}>aos</span>.
        </p>
        <a href="https://github.com/Harp0n" className={[classes.flexElement, classes.copyrightText].join(' ')}>
          Zaprojektowane i stworzone przez <span className={classes.harponText}>Harp0n</span>
        </a>
      </div>
    </footer>
  )
}
export default Footer
