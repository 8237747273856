import React from "react"
import PropTypes from "prop-types"
import Helmet from 'react-helmet'
import favicon from '../images/gatsby-icon.png'
import "./layout.css"
import Navbar from "./Navbar/Navbar"
import Footer from "./Footer/footer"
const Layout = ({ children }) => {
  return (
    <>
    <Helmet>
      <link rel="icon" href={favicon} />
    </Helmet>
      <Navbar />
      <div style={{height:"6vh"}}/>
      <main>{children}</main>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
